import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../../core/security/authentication.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const MAPPING_TAB = 'mapping';
const PROGRAM_CONFIG_TAB = 'program-config';
const DATA_CALENDAR_TAB = 'data-calendar';
const BUDGET_TAB = 'budget';

@Component({
  selector: 'rtpca-navbar',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, TranslateModule],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent {
  public connectedUser = this.authenticationService.connectedUser;

  availableTabs = [
    MAPPING_TAB,
    PROGRAM_CONFIG_TAB,
    BUDGET_TAB,
    DATA_CALENDAR_TAB,
  ];
  selectedTab = MAPPING_TAB;
  currentLang: string;
  constructor(
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    const savedLanguage = localStorage.getItem('language') ?? 'en';
    this.translateService.use(savedLanguage);
    this.currentLang = savedLanguage;
  }

  public logout() {
    this.authenticationService.logout();
  }

  onChangeTab(tab: string): void {
    this.selectedTab = tab;
  }
  switchLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
  }
}
