<nav class="navbar navbar-expand-sm flex-column">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/images/te_logo.png" alt="Logo TotalEnergies" />
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">RTPCA</span>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item ms-md-4">
            <div class="dropdown">
              <span class="navbar-text text-uppercase">{{
                (connectedUser | async)?.username
              }}</span>
              <button
                class="btn btn-outline-primary btn-circle"
                id="profile"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="material-icons-outlined">account_circle</span>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="profile"
              >
                <li>
                  <a class="dropdown-item" href="#" (click)="logout()"
                    >Logout</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item d-none d-xl-block">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="lang"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ currentLang | uppercase }}
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="lang"
              >
                <li>
                  <a
                    (click)="switchLanguage('en')"
                    class="dropdown-item"
                    href="#"
                    >English</a
                  >
                </li>
                <li>
                  <a
                    (click)="switchLanguage('fr')"
                    class="dropdown-item"
                    href="#"
                    >French</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <hr class="separator" />
      <div class="container-fluid">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/"
              [ngClass]="{ active: selectedTab === availableTabs[0] }"
              (click)="onChangeTab(availableTabs[0])"
              >{{ 'navbar.mapping' | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/program-config"
              [ngClass]="{ active: selectedTab === availableTabs[1] }"
              (click)="onChangeTab(availableTabs[1])"
              >{{ 'navbar.programs' | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/budget"
              [ngClass]="{ active: selectedTab === availableTabs[2] }"
              (click)="onChangeTab(availableTabs[2])"
            >
              {{ 'navbar.budget' | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/data-calendar"
              [ngClass]="{ active: selectedTab === availableTabs[3] }"
              (click)="onChangeTab(availableTabs[3])"
            >
              {{ 'navbar.dataCalendar' | translate }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
